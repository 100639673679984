import React from "react";
import browserHistory from "../../browser-history.ts";
import { BackButton } from "@twa-dev/sdk/react";
import './style.css';
import { Col, Container, Row } from "react-bootstrap";
import Logo from '../../assets/logo.png';
import { useTranslation } from "react-i18next";
import { CreateInvoiceLinkResponse, PaymentType } from "../../types/data.ts";
import { APIRoute } from "../../const.ts";
import WebApp from "@twa-dev/sdk";
import { api } from "../../services/api.ts";

const AboutPage = () => {
    const { t, i18n } = useTranslation();
    
    const handleDonationPaymentClick = () => {
        const createInvoiceLink = async () => {
        const paymentData = {
            amount: 1,
            type: PaymentType.Donation,
        };
        const { data: invoiceLinkData } = await api.post<CreateInvoiceLinkResponse>(APIRoute.Payment, paymentData);

        return invoiceLinkData;
        };

        createInvoiceLink().then((result) => {
            WebApp.openInvoice(result.link);
        });
    };

    return (
        <div className="about-page">
            <BackButton onClick={() => browserHistory.back()}/>
            <Container>
                <Row>
                    <Col>
                        <div className="about-content">
                            <header className="about-header">
                                <img src={Logo} alt="Spoty logo" />
                                <h1>Spoty</h1>
                                <p>{t("Version")}</p>
                                <p>{t("Version Date")}</p>
                            </header>
                            <div className="about-contacts">
                                <div className="about-contact-item">
                                    <p>{t("Development")}</p>
                                    <a className="about-contact-link" href="https://t.me/fregire">@fregire</a>
                                </div>
                                <div className="about-contact-item">
                                    <p>{t("Design")}</p>
                                    <a className="about-contact-link" href="https://t.me/starodumov">@starodumov</a>
                                </div>
                                <div className="about-contact-item">
                                    <p>{t("Texts")}</p>
                                    <a className="about-contact-link" href="https://t.me/fucksupercool">@fucksupercool</a>
                                    <a className="about-contact-link" href="https://t.me/artik611">@artik611</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div style={{position: "absolute", bottom: "5px", right: "5px"}} onClick={handleDonationPaymentClick}>
                Пожертвование
            </div>
        </div>
    )
};

export default AboutPage;