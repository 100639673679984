import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Exercise from "../../components/exercise/index.tsx";
import './style.css';
import { BackButton } from "@twa-dev/sdk/react";
import browserHistory from "../../browser-history.ts";
import exercisePreview from '../../assets/exercise-preview-small.png';
import SearchField from "../../components/search-field/index.tsx";
import { useAppDispatch, useAppSelector } from "../../hooks/index.ts";
import { api, getImageURL } from "../../services/api.ts";
import { ExercisePreview } from "../../types/data.ts";
import CategorySection from "../../components/category-section/index.tsx";
import { useDebounce } from "../../hooks/index.ts";
import { useTranslation } from "react-i18next";
import Button from "../../components/button/index.tsx";
import Loader from "../../components/loader/index.tsx";
import { Link } from "react-router-dom";
import { AppRoute } from "../../const.ts";


const NotFoundExercises = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="not-found-exercises">
            <h2>{t("There are no such exercises yet")}</h2>
            <p>{t("Write about not found exercises")}</p>
            {/* TODO: redirect for bot faq */}
            <Link to={AppRoute.Feedback} className="feedback-link">
                {t("Feedback")}
            </Link>
        </div>
    )
};

const CategoryPage = () => {
    const { categories } = useAppSelector((state) => state);
    const { slug } = useParams();
    const category = categories && categories.find((category) => category.slug === slug);
    const dispatch = useAppDispatch();
    const [exercises, setExercises] = useState<null | ExercisePreview[]>(null);
    const [initialCategoryExercises, setInitialCategoryExercises] = useState<null | ExercisePreview[]>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchValue, setSearchValue] = useState<string>("");
    const debouncedSearchValue = useDebounce(searchValue, 500);
    const { t, i18n } = useTranslation();
    
    const renderExercise = (id, title, description) => {
        return (
            <Row>
                <Col>
                    <Exercise id={id} title={title} description={description} preview={exercisePreview} />
                </Col>
            </Row>
        )
    }

    const renderExercises = (exercises: ExercisePreview[]) => {
        const exercisesByCategory: Record<string, ExercisePreview[]> = {};
        const currentCategoryExercises = exercises && exercises.filter((exercise) => exercise.category == category?.name);

        exercises.forEach((exercise) => {
            if (exercise.category === category?.name){
                return;
            }

            if (exercise.category in exercisesByCategory) {
                exercisesByCategory[exercise.category].push(exercise);
            } else {
                exercisesByCategory[exercise.category] = [exercise];
            }
        });

        return (
            <div>
                <div className="category-exercises">
                    {
                        currentCategoryExercises.map(
                            (exercise) => renderExercise(exercise.id, exercise.title, exercise.description)
                        )
                    }
                </div>
                {
                    Object.keys(exercisesByCategory).map((key) => {
                        const exercises = exercisesByCategory[key];
                        return <CategorySection title={key} exercises={exercises}/>
                    })
                }
            </div>
        )
    };

    const handleChangeSearchInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (!category){
            return;
        }

        const fetchExercises = async () => {
            setIsLoading(true);
            const { data: exercisesPreview } = await api.get<ExercisePreview[]>(`/exercises/preview/${category.id}`);
            setExercises(exercisesPreview);
            setInitialCategoryExercises(exercisesPreview);
        };

        fetchExercises().then(_ => setIsLoading(false));
    }, [slug, dispatch]);

    useEffect(() => {
        const fetchExercises = async () => {
            setIsLoading(true);
            const { data: exercisesPreview } = await api.get<ExercisePreview[]>(`/exercises/search?q=${searchValue}`);
            setExercises(exercisesPreview);
        };
        
        if (debouncedSearchValue) {
            fetchExercises().then(_ => setIsLoading(false));
        } else {
            setExercises(initialCategoryExercises);
        };
    }, [debouncedSearchValue]);

    return (
        <div className="category-page">
            <BackButton onClick={() => browserHistory.back()}/>
            <Container>
                <header>
                    <h1>{category && category.name}</h1>
                    {category && <div dangerouslySetInnerHTML={{__html: category.icon}} />}
                </header>
                <div className="search-info">
                    <SearchField onChange={handleChangeSearchInput} placeholder={t('Search')} />
                </div>
                {
                    isLoading 
                    ? <>Loading...</>
                    : (exercises && exercises.length > 0) 
                        ?  renderExercises(exercises)
                        : <NotFoundExercises />
                }
            </Container>
        </div>
    );
};


export default CategoryPage;