import { BackButton, MainButton } from "@twa-dev/sdk/react";
import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import browserHistory from "../../browser-history.ts";
import './style.css';
import { useTranslation } from "react-i18next";
import { api } from "../../services/api.ts";
import { APIRoute } from "../../const.ts";

const FeedbackPage = () => {
    const { t, i18n } = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [feedbackContent, setFeedbackContent] = useState<string>('');
    const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');
    const MIN_REQUIRED_SYMBOLS_COUNT = 10;
    const MAX_REQUIRED_SYMBOLS_COUNT = 1000;

    const handleSendFeedbackForm = () => {
        const sendFeedback = async () => {
            const feedbackData = {
                content: feedbackContent
            };

            return await api.post(APIRoute.Feedback, feedbackData);
        };

        sendFeedback()
        .then((_) => {
                setFeedbackContent('');
                alert(t("Feedback sucessfully sent"));
            }
        )
        .catch((result) => {
            if (result.response.status === 429){
                alert(t("Feedback limit per day"));
            } else if (result.response.status === 413) {
                alert("Feedback too large");
            }
        })
        .finally(() => {
            setFeedbackContent('');
            browserHistory.back();
        });
    };

    const handleChangeFeedbackTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;

        setFeedbackContent(value);
    };

    useEffect(() => {
        if (feedbackContent.length > MAX_REQUIRED_SYMBOLS_COUNT) {
            setValidationErrorMessage("Максимальное количество символов = 1000");
        };

        if (!feedbackContent || feedbackContent.length < MIN_REQUIRED_SYMBOLS_COUNT || feedbackContent.length > MAX_REQUIRED_SYMBOLS_COUNT){
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [feedbackContent]);

    return (
        <div className="feedback-page">
            <BackButton onClick={() => browserHistory.back()}/>
            <Container>
                <Row>
                    <Col>
                        <div className="feedback-container">
                            <h1 className="feedback-headline">{t('Feedback')}</h1>
                            <p className="feedback-description">{t('Feedback description')}</p>
                            <form action="">
                                <textarea
                                    name="feedback"  
                                    placeholder={t('Feedback textarea placeholder')}
                                    className="feedback-textarea"
                                    onChange={handleChangeFeedbackTextarea}
                                    value={feedbackContent}
                                >
                                </textarea>
                                <p className="feedback-error">{validationErrorMessage}</p>
                                <MainButton
                                    text={t("Send")} 
                                    color={disabled ? "#F2F3F5" : "#007AFF"}
                                    textColor={disabled ? "#000000" : "#FFFFFF"}
                                    onClick={handleSendFeedbackForm}
                                    disabled={disabled}
                                /> 
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};


export default FeedbackPage;